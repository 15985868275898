import React, { memo, useEffect, useState } from 'react'
import { useSetRecoilState, useResetRecoilState, useRecoilValue } from 'recoil'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import RadioChecked from '@material-ui/icons/RadioButtonChecked'
import RadioUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import TableList from 'components/TableList'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import { FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import outletTypes from './partials/EditOutfalls/types'

import {
  options as optionsState,
  predefinedComponents as predefinedComponentsState,
} from 'containers/Dilution/states/activeScenarioType'

import dischargeOptionsState from 'containers/Dilution/states/dischargeOptions'
import mapSourceState from 'containers/Dilution/states/mapSource'
import Block from 'components/Block'

import mapExistingOutlet from '../../lib/mapExistingOutlet'
import Modal from './partials/Modal'
import Popper from 'components/Popper'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import EditAll from './partials/EditAll'
import EditSelectedOutfalls from './partials/EditOutfalls/EditSelectedOutfalls'
import EditSelectedConcentrations from './partials/EditConcentrations/EditSelectedConcentrations'
import OutletSelectionButton from './partials/OutletSelectionButton'

const Step = ({ form }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [open, setOpen] = useState(false)
  const [openEditAll, setOpenEditAll] = useState(false)
  const [openEditOutfalls, setOpenEditOutfalls] = useState(false)
  const [openEditSelected, setOpenEditSelected] = useState(false)
  const { setFieldValue, values, handleChange } = form
  const { components, outlet = {} } = values
  const { existingOutlet = [], dischargeOption } = outlet
  const [isEnabled, setIsEnabled] = useState(
    existingOutlet.some((item) => !!item.include)
  )
  const { t } = useTranslation()

  // const existingSourcesInList = useRecoilValue(existingSourcesInListState)

  const options = useRecoilValue(optionsState)
  const predefinedComponents = useRecoilValue(predefinedComponentsState)
  const dischargeOptions = useRecoilValue(dischargeOptionsState)
  const setMapSource = useSetRecoilState(mapSourceState)
  const resetMapSource = useResetRecoilState(mapSourceState)

  const { fixedOutletConcentration } = options

  const handleToggle = ({ event, listIndex }) => {
    const { target } = event
    const { checked } = target
    setFieldValue(`outlet.existingOutlet.${listIndex}.include`, checked)
  }

  const handleEdit = (listIndex) => {
    // setActiveSourceId(id)
    setActiveIndex(listIndex)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    // setActiveSourceId(null)
    setActiveIndex(null)
  }

  useEffect(() => {
    return () => {
      resetMapSource()
    }
  }, [])

  useEffect(() => {
    setMapSource(
      existingOutlet.map(({ source, include }) => ({
        name: source.label,
        lat: source.latitude,
        lon: source.longitude,
        include,
      }))
    )
  }, [existingOutlet])

  useEffect(() => {
    handleDischarges()
  }, [dischargeOption])

  const handleDischargeOptionId = (newActiveDischargeOptionId) => {
    setFieldValue('outlet.dischargeOption', newActiveDischargeOptionId)
  }

  const handleDischarges = () => {
    setFieldValue(
      `outlet.existingOutlet`,
      mapExistingOutlet({
        existingOutlet,
        dischargeOption,
        fixedOutletConcentration,
        predefinedComponents,
        components,
      })
    )
  }

  const handleConcentrations = () => {
    const concentrations = get(
      outlet,
      `existingOutlet[${activeIndex}].concentrations`,
      []
    )
    const dictionary = concentrations.reduce(
      (allConcentrations, thisConcentration) => {
        const { componentDescription, concentrationValue } = thisConcentration
        allConcentrations[componentDescription] = concentrationValue
        return allConcentrations
      },
      {}
    )

    const getConcentrations = (list) =>
      list.map(
        ({
          componentDescription,
          concentrationValue,
          unit,
          include = true,
        }) => {
          const value = dictionary[componentDescription] || concentrationValue
          return {
            componentDescription,
            concentrationValue: concentrationValue || value || 0,
            unit,
            include,
          }
        }
      )
    const concentrationsToSet = fixedOutletConcentration
      ? getConcentrations(predefinedComponents)
      : getConcentrations(components)

    setFieldValue(
      `outlet.existingOutlet[${activeIndex}].concentrations`,
      concentrationsToSet
    )
  }

  const handleEnable = (event) => {
    const checked = event.target.checked
    setIsEnabled(checked)
    setFieldValue(
      `outlet.existingOutlet`,
      existingOutlet.map(({ include, ...item }) => ({
        ...item,
        include: checked,
      }))
    )
  }

  useEffect(() => {
    if (activeIndex !== null) handleConcentrations()
  }, [activeIndex])

  const withExistingOutlet = !!existingOutlet.length

  const handleUpdateConcentrations = (values) => {
    const existingOutletUpdated = existingOutlet.map(
      ({ concentrations, ...rest }) => {
        const concentrationsUpdated = concentrations.map((item, index) => ({
          ...item,
          concentrationValue: values[index],
        }))

        return { ...rest, concentrations: concentrationsUpdated }
      }
    )
    setFieldValue(`outlet.existingOutlet`, existingOutletUpdated)
    setOpenEditAll(false)
  }

  const handleUpdateSelectedConcentrations = (values) => {
    if (values === '' || values.length === 0) return

    const existingOutletUpdated = existingOutlet.map(
      ({ concentrations, include, ...rest }) => {
        if (!include) return { ...rest, concentrations }

        const valueMap = new Map(values.map(({ name, value }) => [name, value !== '' ? parseFloat(value) : value]));

        const concentrationsUpdated = concentrations.map((item) => ({
          ...item,
          concentrationValue: valueMap.get(item.componentDescription)
        }));

        return { ...rest, include, concentrations: concentrationsUpdated }
      }
    )

    setFieldValue(`outlet.existingOutlet`, existingOutletUpdated)
    setOpenEditSelected(false)
  }

  const handleUpdateSelectedOutfalls = (type) => {
    const outfallType = type.value;

    const existingOutletUpdated = existingOutlet.map(
      (outlet) => {
        if (!outlet.include) return outlet;

        const { source } = outlet;
        source.type = outfallType;

        return outlet
      }
    )

    setFieldValue(`outlet.existingOutlet`, existingOutletUpdated)

    setOpenEditOutfalls(false);
  }

  const handleOnSelectTypeToEdit = (type) => {
    const existingOutletCopy = JSON.parse(JSON.stringify(existingOutlet));

    const selectedOutlets = existingOutletCopy.map((outlet) => {
      if (type === 'All') {
        outlet.include = true;
        return outlet;
      }

      outlet.include = outlet.source.type && outlet.source.type === type;
      return outlet;
    });

    setFieldValue(`outlet.existingOutlet`, selectedOutlets)
  }

  const enabledOutlets = existingOutlet.filter((outlet) => outlet.include)

  const availableTypes = ['All', ...outletTypes.map((type) => type.label)];
  // const availableTypes = ['All', ...new Set(existingOutlet.map((outlet) => outlet.source.type ? outlet.source.type : null).filter((i) => !!i))]

  return (
    <>
      <Box width={1} height={1}>
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Popper
            icon={<HelpOutlineIcon />}
            popperContent={t('documentation:existingOutletsTitle')}
            placement="left"
          />
        </Box>
        <Box marginBottom={4}>
          {withExistingOutlet ? (
            <>
              <Box marginBottom={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isEnabled} onChange={handleEnable} />
                      }
                      label={t('dilution:enableExistingSources')}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box marginBottom={4}>
                <Block headline={t('common:chooseDischargeEvent')}>
                  <List>
                    {dischargeOptions.map(({ id, label }) => (
                      <ListItem
                        key={id}
                        dense
                        button
                        disableGutters
                        onClick={() => handleDischargeOptionId(id)}
                        disabled={!isEnabled}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={dischargeOption === id}
                            onChange={() => handleDischargeOptionId(id)}
                            icon={<RadioUnchecked fontSize="small" />}
                            checkedIcon={<RadioChecked fontSize="small" />}
                            color="primary"
                            disableRipple
                            disabled={!isEnabled}
                          />
                        </ListItemIcon>
                        <ListItemText primary={label} />
                      </ListItem>
                    ))}
                  </List>
                </Block>
              </Box>
              <Box p={2} bgcolor="#fafafa" borderBottom="1px solid #ccc">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {t("common:editOutfallForSelectedOutlets")}&nbsp;
                    {enabledOutlets.length === 0
                      ? null
                      : `(${enabledOutlets.length})`}
                  </Typography>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={enabledOutlets.length === 0}
                    startIcon={<EditIcon />}
                    onClick={() => setOpenEditOutfalls(true)}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
              <Box p={2} bgcolor="#fafafa">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {t("common:editConcentrationForSelectedOutlets")}&nbsp;
                    {enabledOutlets.length === 0
                      ? null
                      : `(${enabledOutlets.length})`}
                  </Typography>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<EditIcon />}
                    disabled={enabledOutlets.length === 0}
                    onClick={() => setOpenEditSelected(true)}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
              <TableList
                columns={[
                  {
                    id: 'checkbox',
                    heading: (<OutletSelectionButton options={availableTypes} onClose={(type) => handleOnSelectTypeToEdit(type)} />),
                    component: memo(({ listIndex, include }) => (
                      <Tooltip title="Toggle">
                        <div>
                          <Checkbox
                            checked={include}
                            name={`${listIndex}.include`}
                            id={`${listIndex}.include`}
                            color="primary"
                            disabled={!isEnabled}
                            onChange={(event) =>
                              handleToggle({ event, listIndex })
                            }
                          />
                        </div>
                      </Tooltip>
                    )),
                    options: {
                      style: {
                        maxWidth: '75px',
                      },
                    },
                  },
                  {
                    id: 'name',
                    heading: t('common:name'),
                    field: 'source.label',
                  },
                  {
                    id: 'type',
                    heading: "Type", // translate
                    field: 'source.type',
                    component: memo((row) => row.source.type ? row.source.type : '--'),
                  },
                  {
                    id: 'edit',
                    heading: '',
                    component: ({ id, listIndex }) => (
                      <Tooltip title="Edit">
                        <div>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEdit(listIndex)}
                            disabled={!isEnabled}
                          >
                            <EditIcon
                              fontSize="small"
                              color={isEnabled ? 'primary' : 'disabled'}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>
                    ),
                  },
                ]}
                list={existingOutlet}
              />
            </>
          ) : (
            <Box
              marginBottom={4}
              padding={4}
              display="flex"
              flexDirection="colunn"
              justifyContent="center"
              alignItems="center"
              bgcolor="shades.light"
              border={1}
              borderColor="shades.medium"
            >
              <Typography color="primary" variant="subtitle1">
                {t('dilution:noExistingOutlet')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <EditAll
        components={components}
        translations={t}
        open={openEditAll}
        handleClose={() => setOpenEditAll(false)}
        handleUpdate={handleUpdateConcentrations}
      />

      <EditSelectedConcentrations
        translations={t}
        open={openEditSelected}
        enabledOutlets={enabledOutlets}
        handleSave={handleUpdateSelectedConcentrations}
        handleClose={() => setOpenEditSelected(false)}
      />

      <EditSelectedOutfalls
        translations={t}
        open={openEditOutfalls}
        enabledOutlets={enabledOutlets}
        handleSave={handleUpdateSelectedOutfalls}
        handleClose={() => setOpenEditOutfalls(false)}
      />

      <Modal open={open} handleClose={handleClose} handleSave={handleClose}>
        <Box marginBottom={4}>
          <FieldArray
            name={`outlet.existingOutlet.${activeIndex}.concentrations`}
            render={(arrayHelpers) => (
              <>
                {activeIndex !== null
                  ? existingOutlet[activeIndex].concentrations.map(
                    (concentration, index) => (
                      <Box key={index}>
                        <Grid container spacing={2} alignItems="flex-end">
                          <Grid item xs={5}>
                            <Block
                              headline={t('common:componentDescription')}
                            >
                              <TextField
                                fullWidth
                                autoComplete="off"
                                onChange={handleChange}
                                id={`outlet.existingOutlet.${activeIndex}.concentrations[${index}].componentDescription`}
                                name={`outlet.existingOutlet.${activeIndex}.concentrations[${index}].componentDescription`}
                                value={
                                  existingOutlet[activeIndex].concentrations[
                                    index
                                  ].componentDescription
                                }
                                placeholder="label"
                                disabled
                              />
                            </Block>
                          </Grid>
                          <Grid item xs={5}>
                            <Block headline={t('common:concentrationValue')}>
                              <TextField
                                fullWidth
                                autoComplete="off"
                                onChange={handleChange}
                                id={`outlet.existingOutlet.${activeIndex}.concentrations.${index}.concentrationValue`}
                                name={`outlet.existingOutlet.${activeIndex}.concentrations.${index}.concentrationValue`}
                                value={
                                  existingOutlet[activeIndex].concentrations[
                                    index
                                  ].concentrationValue
                                }
                                placeholder={t('common:typeValue')}
                                disabled={
                                  !concentration.include ||
                                  fixedOutletConcentration
                                }
                                type="number"
                              />
                            </Block>
                          </Grid>
                          <Grid item xs={2}>
                            <Block headline={t('common:unit')}>
                              <TextField
                                fullWidth
                                autoComplete="off"
                                id={`outlet.existingOutlet.${activeIndex}.concentrations.${index}.unit`}
                                name={`outlet.existingOutlet.${activeIndex}.concentrations.${index}.unit`}
                                value={
                                  existingOutlet[activeIndex].concentrations[
                                    index
                                  ].unit
                                }
                                disabled
                              />
                            </Block>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  )
                  : null}
              </>
            )}
          />
        </Box>
      </Modal>
    </>
  )
}

export default Step
