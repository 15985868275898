import React, { useEffect } from 'react'
import { Box, TextField, Chip, Typography } from '@material-ui/core'

import Modal from '../Modal'
import Block from 'components/Block'

const EditSelectedConcentrations = ({
  open,
  enabledOutlets,
  handleClose,
  handleSave,
  translations,
}) => {
  const [state, setState] = React.useState([])

  const handleOnSaveAndClose = () => {
    handleSave(state);
  }

  const handleOnChange = (e) => {

    const name = e.target.name;
    const value = e.target.value !== '' ? parseFloat(e.target.value) : '';

    const components = [...state];
    const componentToChangeIndex = components.findIndex((c) => c.name === name);
    components[componentToChangeIndex].value = value;

    setState(components)
  }

  useEffect(() => {
    // Reset on close
    if (!open) {
      setState([])
    }

    // Extract components
    if (!enabledOutlets || enabledOutlets.length === 0) return

    const components = [];

    enabledOutlets.forEach(({ concentrations }) => concentrations.forEach((c) => {
      const componentExists = components.findIndex((component) => component.name.includes(c.componentDescription))
      if (componentExists !== -1) return;

      components.push({
        name: c.componentDescription,
        value: c.concentrationValue,
        unit: c.unit,
      });
    }));

    setState(components);
  }, [open])

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSave={handleOnSaveAndClose}
    >
      <Box display="flex" flexDirection="column">
        <Box mb={2} textAlign="center">
          <Typography variant="h6">
            {translations("common:editConcentrationsDialogTitle")}:
          </Typography>
        </Box>
        <Box
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
          display="flex"
          flexWrap="wrap"
          maxHeight="200px"
          border="1px solid #ccc"
          bgcolor="#fafafa"
          p={1}
        >
          {enabledOutlets.map((outlet) => {
            const { source } = outlet

            return (
              <Box mr={1} mb={1}>
                <Chip
                  label={source.label}
                  style={{ backgroundColor: '#fff' }}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box display="flex" alignItems="end" mt={2} border="1px solid #ccc" p={1}>
        {state.length ? state.map((component) => (
          <>
            <Block headline={`${component.name} (${component.unit})`} px={1}>
              <TextField
                fullWidth
                type="number"
                autoComplete="off"
                name={component.name}
                value={component.value}
                onChange={handleOnChange}
                placeholder={translations('common:concentrationValue')}
              />
            </Block>
          </>
        )) : null}
      </Box>
    </Modal>
  )
}

export default EditSelectedConcentrations
