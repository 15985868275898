import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Block from 'components/Block'
import Modal from '../Modal'

const EditAll = ({
  components,
  translations,
  open,
  handleClose,
  handleUpdate,
}) => {
  const [state, setState] = useState({})

  const handleChange = (event) => {
    const { target } = event
    const { value, id } = target

    setState((prevState) => ({ ...prevState, [id]: value }))
  }

  const handleSaveAndClose = () => {
    const values = Object.keys(state).map((key) => checkValue(state[key]))
    handleUpdate(values)
  }

  const checkValue = (valAsString) => {
    if (valAsString && valAsString !== '') {
      return parseFloat(valAsString)
    }

    return 0
  }

  useEffect(() => {
    const updateState = {}
    components.forEach((_, index) => {
      updateState[`${index + 1}`] = 0
    })

    setState(updateState)
  }, [components])

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSave={handleSaveAndClose}
    >
      <Box marginBottom={4}>
        {components.map(({ componentDescription, unit }, index) => (
          <Box key={index}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={5}>
                <Block headline={translations('common:componentDescription')}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id={`${componentDescription}-name`}
                    name={`${componentDescription}-name`}
                    value={componentDescription}
                    placeholder="label"
                    disabled
                  />
                </Block>
              </Grid>
              <Grid item xs={5}>
                <Block headline={translations('common:concentrationValue')}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id={`${index + 1}`}
                    name={`${index + 1}`}
                    onChange={(event) => handleChange(event, index)}
                    value={state[`${index + 1}`] || 0}
                    placeholder={translations('common:typeValue')}
                    type="number"
                  />
                </Block>
              </Grid>
              <Grid item xs={2}>
                <Block headline={translations('common:unit')}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={unit}
                    id={`${componentDescription}-unit`}
                    name={`${componentDescription}-unit`}
                    disabled
                  />
                </Block>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </Modal>
  )
}

export default EditAll
